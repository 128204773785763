import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';

import { firebaseConfig } from '../constants/defaultValues';

firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();
const auth = firebase.auth();
const providers = new firebase.auth.GoogleAuthProvider();
const database = firebase.database();

export { auth, database, providers, analytics };
