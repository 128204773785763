import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { auth, providers } from '../../helpers/Firebase';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';
import { apiCall } from '../../config/axiosApiCallWrapper';
import { initLoading, stopLoading } from '../settings/actions';
import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';
import { adminRoot } from '../../constants/defaultValues';
import { setCurrentUser } from '../../helpers/Utils';

const MySwal = withReactContent(Swal);

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async () =>
  // eslint-disable-next-line no-return-await
  await auth
    .signInWithPopup(providers)
    .then((user) => user)
    .catch((error) => error);

function* continueLogin(credentials, history) {
  const response = yield call(apiCall, 'POST', 'landing/auth/login', {
    credentials,
  });
  if (response.success) {
    setCurrentUser(response.user);
    yield put(loginUserSuccess(response.user));
    history.push(adminRoot);
    yield put(stopLoading());
  } else {
    yield put(loginUserError(response.message));
    yield put(stopLoading());
  }
}

function* loginWithEmailPassword({ payload }) {
  yield put(initLoading());
  const { provider, history, data } = payload;
  try {
    if (provider === 'EMAIL') {
      const credentials = {
        email: data.correo,
        contrasena: data.contrasena,
      };
      yield call(continueLogin, credentials, history);
    } else {
      const loginUser = yield call(loginWithEmailPasswordAsync);
      if (!loginUser.message) {
        const credentials = {
          email: loginUser.additionalUserInfo.profile.email,
          contrasena: loginUser.additionalUserInfo.profile.id,
        };
        yield call(continueLogin, credentials, history);
      } else {
        yield put(loginUserError(loginUser.message));
        yield put(stopLoading());
      }
    }
  } catch (error) {
    yield put(loginUserError(error));
    yield put(stopLoading());
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* continueRegister(formData, history) {
  const registerUser = yield call(apiCall, 'POST', 'landing/auth/register', {
    formData,
  });
  if (registerUser.success) {
    setCurrentUser(registerUser.user);
    yield put(registerUserSuccess(registerUser.user));
    history.push(adminRoot);
    MySwal.fire({
      icon: 'success',
      title: 'Exito!',
      text: 'Te has registrado correctamente!',
    });
  } else {
    yield put(registerUserError(registerUser.message));
    MySwal.fire({
      icon: 'error',
      title: 'Opss...!',
      text: registerUser.message,
    });
  }
  yield put(stopLoading());
}

function* registerWithEmailPassword({ payload }) {
  yield put(initLoading());
  const formData = payload.user;
  const { history } = payload;
  try {
    if (formData.provider === 'EMAIL') {
      const registerUserMail = yield call(
        registerWithEmailPasswordAsync,
        formData.usuario,
        formData.contrasena
      );
      if (!registerUserMail.message) {
        yield call(continueRegister, formData, history);
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Opss...!',
          text: registerUserMail.message,
        });
        yield put(registerUserError(registerUserMail.message));
        yield put(stopLoading());
      }
    } else {
      yield call(continueRegister, formData, history);
    }
  } catch (error) {
    yield put(registerUserError(error));
    yield put(stopLoading());
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
